import React from 'react';
import { createRoot } from 'react-dom/client'; // Updated import for React 18
import App from './app/app';

// Initialize root with the new `createRoot` API
const rootElement = document.getElementById('root');
if (rootElement) {
  const root = createRoot(rootElement); // Use createRoot instead of render

  document.addEventListener('DOMContentLoaded', () => {
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  });
}