import Wizard, { WizardProps } from '../../components-v2/Wizard';
import React, { useEffect } from 'react';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import { getOneRosterFileApiBaseUrl } from '../../utils/constants';
import apiClient from '../../utils/apiClient';
import axios from 'axios';
import SchoolMapping from '../PSIntegrationSetup/SchoolMap';
import { SisCredentialResponse, SisSchool } from '../../../../../../libs/common-interfaces';

const fetchOneRosterSchools = async (
  setSisSchools: React.Dispatch<React.SetStateAction<SisSchool[]>>,
  setUnusedSchools: React.Dispatch<React.SetStateAction<SisSchool[]>>,
) => {
  try {
    const res = await axios.get(`${getOneRosterFileApiBaseUrl()}/oneroster/job/latest/status`, {
      withCredentials: true,
    });
    let dataJsonFile: any;
    if (res.data.status === 'COMPLETED') {
      dataJsonFile = res.data.presignedUrl;
    }
    const response = await axios.get(dataJsonFile);
    const { schoolMap } = response.data;

    const updatedSchoolMap = schoolMap.map((school) => ({
      ...school,
      school_number: school.id,
      dcid: '',
      abbreviation: '',
    }));

    setSisSchools(updatedSchoolMap);
    setUnusedSchools(updatedSchoolMap);
  } catch (error) {
    showNotification(NotificationTypes.error, 'Error Getting School Map', 'Failure in getting data from server.');
  }
};

const saveSchoolMapping = async (schoolMapping: any) => {
  try {
    const data = Object.keys(schoolMapping).reduce((acc, nid) => {
      const { sisId } = schoolMapping[nid];
      sisId.forEach((id) => {
        acc.push({ sisId: id, nid });
      });
      return acc;
    }, []);

    const totalMappings = data.length;
    const batchSize = 125;
    const batches = Array.from({ length: Math.ceil(totalMappings / batchSize) }, (v, i) =>
      data.slice(i * batchSize, i * batchSize + batchSize),
    );

    for (let i = 0; i < batches.length; i++) {
      await apiClient.patch('/data-ingest/sis/parameter-group/schoolMappingBatches', {
        schoolMapping: batches[i],
        batchNumber: i,
      });
    }

    showNotification(NotificationTypes.success, 'Success', 'Mapping updated successfully');
  } catch (error) {
    showNotification(NotificationTypes.error, 'Error Saving School Mappings', 'Failure in saving data on server.');
  }
};

const handleSave = async (
  saveSchoolMappingFn: () => Promise<void>,
  saveFn: () => void,
  continueFn: () => void,
  saveAndContinue?: boolean,
) => {
  try {
    await saveSchoolMappingFn();
    if (saveAndContinue) {
      continueFn();
    } else {
      showNotification(NotificationTypes.success, 'Save Successful', '');
      saveFn();
    }
  } catch (err) {
    console.error(err.message);
    showNotification(NotificationTypes.error, 'Error Saving Mapping', 'Server Error');
  }
};

const handleCancel = (cancelFn: () => void, previousFn: () => void, cancelAndBacktrack?: boolean) => {
  if (cancelAndBacktrack) {
    previousFn();
  } else {
    cancelFn();
  }
};

const OneRosterSchoolMap = (props: WizardProps): React.ReactElement => {
  const [sisSchools, setSisSchools] = React.useState<SisSchool[]>([]);
  const [unusedSchools, setUnusedSchools] = React.useState<SisSchool[]>([]);
  const [schoolMapping, setSchoolMapping] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [credentials, setCredentials] = React.useState<SisCredentialResponse>({
    host: '',
    clientId: '',
    discoveryUrl: '',
    sisIntegration: '',
    dataHost: '',
  });

  const save = async (saveAndContinue?: boolean) => {
    await handleSave(() => saveSchoolMapping(schoolMapping), props.saveFn, props.continueFn, saveAndContinue);
  };

  const cancel = (cancelAndBacktrack?: boolean) => {
    handleCancel(props.cancelFn, props.previousFn, cancelAndBacktrack);
  };

  const saveAndClose = async () => {
    await save();
    cancel();
  };

  const changeStep = (newStep: number) => {
    props.changeStepFn(newStep);
  };

  return (
    <Wizard
      steps={props.steps}
      currentStep={props.currentStep}
      continueFn={() => save()}
      previousFn={() => cancel(true)}
      saveFn={() => save()}
      cancelFn={() => cancel()}
      saveAndCloseFn={saveAndClose}
      changeStepFn={changeStep}
      hideHeaderButtons={true}
      hideFooter={false}
    >
      <div>
        <SchoolMapping
          sisSchools={sisSchools}
          unusedSisSchools={unusedSchools}
          setUnusedSisSchools={setUnusedSchools}
          fetchSisSchools={() => fetchOneRosterSchools(setSisSchools, setUnusedSchools)}
          schoolMapping={schoolMapping}
          setSchoolMapping={setSchoolMapping}
          schoolMatchLoading={loading}
          setSchoolMatchLoading={setLoading}
          credentials={credentials}
        />
      </div>
    </Wizard>
  );
};

export default OneRosterSchoolMap;
