import { Upload, Button, Input, Alert, Spin } from 'antd';
import React, { useEffect } from 'react';
import { get } from 'lodash';
import Col from '../../components-v2/Col';
import Divider from '../../components-v2/Divider';
import Row from '../../components-v2/Row';
import AlertErrorIcon from '../../../assets/Icon/AlertErrorIcon';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import { WizardProps } from '../../components-v2/Wizard';
import Wizard from '../../components-v2/Wizard';
import { getOneRosterFileApiBaseUrl } from '../../utils/constants';
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import ConfigureEmail from '../ConfigureEmail';
export interface OneRosterImportStep {
  title: string;
  content: React.FunctionComponent<any>;
  path: string;
}

export interface OneRosterImportProps {
  steps: OneRosterImportStep[];
}

const MAX_FILE_SIZE_BYTES = 1 * 1024 * 1024 * 1024; // 1GB

const OneRosterFileUpload = (props: WizardProps): React.ReactElement => {
  const [fileSelectedName, setFileSelectedName] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [files, setFiles] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [isUploadInProgress, setIsUploadInProgress] = React.useState(false);
  const [uploadFileMessage, setUploadFileMessage] = React.useState('');
  const [disableNext, setDisableSaveNext] = React.useState(false);
  const [importSettings, setImportSettings] = React.useState({
    updateExistingRecords: false,
    addNewRecords: false,
  });

  const getPresignedUrl = async () => {
    // Call Generate Presigned URL API to get the URL
    const baseUrl = getOneRosterFileApiBaseUrl();
    const url = `${baseUrl}/oneroster/job/generate-url`;
    try {
      const response = await axios.get(url, { withCredentials: true });
      return response.data;
    } catch (error) {
      console.error('Error getting presigned URL:', error);
      return error;
    }
  };

  const uploadFile = async () => {
    // Call Upload File API to upload the file
    setDisableSaveNext(true);
    setUploadFileMessage('Uploading file...');
    setIsUploadInProgress(true);
    const response = await getPresignedUrl();
    if (!response) {
      return;
    }
    try {
      const presignedUrl = response.presignedUrl;
      const jobId = response.jobId;
      await axios.put(presignedUrl, files, {
        headers: {
          'Content-Type': 'application/zip',
        },
      });
      console.log('File uploaded successfully:', response.data);
      setUploadFileMessage('File uploaded successfully...');
      return jobId;
    } catch (error) {
      console.error('Error uploading file:', error);
      setErrorMessage('Error uploading file');
      setDisableSaveNext(false);
      return null;
    }
  };

  const selectedFile = async (file) => {
    if (file.file.size >= MAX_FILE_SIZE_BYTES) {
      setErrorMessage('The file size exceeds the maximum limit of 1GB.');
      return;
    }

    const fileName = (file.file as File).name;
    const expectedFileName = 'oneRoster';
    if (!fileName.startsWith(expectedFileName)) {
      const extension = fileName.includes('.') ? fileName.split('.').slice(-1)[0] : '.zip';
      if (extension !== 'zip') {
        setErrorMessage('Wrong file format. Only .zip files are accepted.');
        return;
      }
      setErrorMessage(`Wrong file name. File must be named ${expectedFileName}.${extension}`);
      return;
    }

    setErrorMessage('');

    setFileSelectedName(file.file.name);
    setFiles(file.file);
    try {
      // setFileHeaders(await readFileHeaders(file.file));
    } catch (err) {
      setErrorMessage('Wrong file naming convention. File naming convention must be: oneRoster.zip');
    }
  };

  const saveFile = async () => {
    if (files === '') {
      showNotification(NotificationTypes.error, 'Upload File', 'Please upload file to save import.');
      return false;
    }
    setLoading(true);
    try {
      const jobId = await uploadFile();
      setUploadFileMessage('Validating file data...');
      // Call Status API to get the status of the job
      if (!jobId) {
        setLoading(false);
        setIsUploadInProgress(false);
        setFiles('');
        setFileSelectedName('');
        setUploadFileMessage('');
        showNotification(
          NotificationTypes.error,
          'Error Importing File',
          'Failed to upload the file. Please try again later.',
        );
        setDisableSaveNext(true);
        return false;
      }
      const intervalId = setInterval(async () => {
        try {
          const response = await axios.get(`${getOneRosterFileApiBaseUrl()}/oneroster/job/${jobId}/status`, {
            withCredentials: true,
          });

          if (response.data.status === 'COMPLETED') {
            // To be used in the next page
            showNotification(
              NotificationTypes.success,
              'File Imported Successfully',
              'The file has been imported successfully.',
            );
            setLoading(false);
            setIsUploadInProgress(false);
            setFileSelectedName('');
            setFiles('');
            clearInterval(intervalId); // Use intervalId to stop the interval
            setDisableSaveNext(false);
          }
        } catch (apiError) {
          clearInterval(intervalId); // Stop interval in case of an error
          console.error('Error fetching job status:', apiError);
          showNotification(
            NotificationTypes.error,
            'Error Checking Job Status',
            'Failed to get the job status. Please try again later.',
          );
          return false;
        }
      }, 2000);
    } catch (error) {
      setLoading(false);
      setDisableSaveNext(true);
      let errorMessage = 'Failure in sending data the server.';
      if (get(error, 'response.status') === 401) {
        errorMessage = 'You are not authorized to make this change or perform this action on behalf of the client.';
      }
      showNotification(NotificationTypes.error, 'Error Importing File', errorMessage);
      return false;
    }
  };

  useEffect(() => {
    if (importSettings.updateExistingRecords || importSettings.addNewRecords) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [importSettings]);

  const mainContent = (
    <>
      <Row style={{ minHeight: '100vh' }}>
        <Col span={17}>
          <div className="section" data-testid="one-roster-file-upload">
            <Col span="18">
              <h2 className="uploadHeading">Note:</h2>
            </Col>
            <Row>
              <ul>
                <li>
                  {' '}
                  File naming convention must be: <b>oneRoster.zip </b>
                </li>
                <li> Only zip files are accepted. Max size of 1GB </li>
              </ul>
            </Row>
          </div>
          <div className="section" data-testid="one-roster-file-upload">
            <Col span="18">
              <h2 className="uploadHeading">Data file:</h2>
            </Col>
          </div>
          <div className="subSection">
          <Row className="uploadSection">
            <Col span="18">
              <div className="fileUploadbtn" style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                {/* "Choose File" button */}
                <Col style={{ display: 'inline-block' }} span="4">
                  <Upload showUploadList={false} accept=".zip" customRequest={selectedFile}>
                    <button className="button responsive-button" data-testid="file_upload_btn">
                      Choose File
                    </button>
                  </Upload>
                </Col>

                {/* Spinner and Upload Message */}
                <Col style={{ display: 'inline-block' }} span="18">
                  <div>
                    {isUploadInProgress && (
                      <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                        <Spin indicator={<LoadingOutlined spin />} size="large" />
                        <span>{uploadFileMessage}</span>
                      </div>
                    )}
                    {/* Error Message */}
                    <span style={errorMessage !== '' ? {} : { display: 'none' }}>
                      <AlertErrorIcon />
                      <span style={{ color: 'red'}}>{errorMessage}</span>
                    </span>
                  </div>
                </Col>
              </div>
            </Col>
          </Row>

          </div>
          <div className="section">
            <Col span="18">
              <Divider></Divider>
            </Col>
            <Row justify="start">
              
              <Col span="18">
                <h2 className="uploadHeading">File name</h2>
              </Col>
              <Col span={18}>
                <div>
                  <input
                    className="fileNameInputBox drawerInputFull"
                    type="text"
                    defaultValue={fileSelectedName}
                    placeholder="File Name"
                    data-test-id="input_oneRosterFileName"
                    style={{ lineHeight: '36px !important' }}
                  />
                </div>
              </Col>
              <Col span={18}>
                <Row>
                  <h2 className="uploadHeading">Email recipients</h2>
                </Row>
                <Row>
                  <span style={{ paddingTop: '10px' }} className="uploadcont">
                    Add recipients to receive email notifications about the import.
                  </span>
                </Row>
                <br />
                <ConfigureEmail />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
  const save = async (saveAndContinue?: boolean) => {
    try {
      await saveFile();
      if (saveAndContinue && !disableNext) {
        props.continueFn();
      } else {
        showNotification(NotificationTypes.success, 'Save Successful', '');
        props.saveFn();
      }
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, 'Error Saving Mapping', 'Server Error');
    }
  };
  const cancel = (cancelAndBacktrack?: boolean) => {
    if (cancelAndBacktrack) {
      props.previousFn();
    } else {
      props.cancelFn();
    }
  };
  const changeStep = (newStep: number) => {
    props.changeStepFn(newStep);
  };

  const saveAndClose = async () => {
    await save();
    await cancel();
  };
  return (
    <Wizard
      steps={props.steps}
      currentStep={props.currentStep}
      continueFn={() => save(true)}
      previousFn={() => cancel(true)}
      saveFn={() => save()}
      cancelFn={() => cancel()}
      saveAndCloseFn={saveAndClose}
      changeStepFn={changeStep}
      hideHeaderButtons={true}
      disableSaveNext={disableNext}
    >
      {mainContent}
    </Wizard>
  );
};

export default OneRosterFileUpload;
