import React from "react";
import { Col, Row, Table, TablePaginationConfig, Tag } from "antd";
import  { ColumnsType } from 'antd/lib/table';
import apiClient from "../../utils/apiClient";
import { IntegrationContext } from "../../utils/context";
import ChipIcon from "../../../assets/Icon/ChipIcon";

export enum TabType {
  Error = 'Error',
  Warning = 'Warning'
};

export interface StudentWarningTab {
gpa: string 
zip: string
city: string
rank: string
email: string 
state: string
gender: string
birthDate: string
middleName: string
weightedGpa: string
streetAddress: string
stateStudentId: string
grade: string
homePhone: string 
ethnicCode: string 
studentGroup : string
originalClassYear: string
}
export interface StudentsErrorsAndWarningsTab {
  jobGuid: string;
  filters: string[];
  type: TabType;
  dataType: string;
}

const StudentsErrorsAndWarningsTab = ({
  jobGuid,
  filters,
  type,
  dataType,
}: StudentsErrorsAndWarningsTab): React.ReactElement => {
  const { isCleverIntegration } = React.useContext(IntegrationContext);
  const [loading, setLoading] = React.useState<boolean>();
  const [currentPageNum, setCurrentPageNum] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [selectedFilters, setSelectedFilters] = React.useState<string[]>([]);

  const pagination = {
    position: ['bottomCenter'],
    size: "small",
    total: totalRecords,
    showSizeChanger: true,
    showQuickJumper: true,
    current: currentPageNum,
    pageSize: pageSize,
    showTotal: (total) => `${total} results`,
    pageSizeOptions: [10, 15],
    onShowSizeChange: async (currentPage, size) => {
      setPageSize(size);
      setCurrentPageNum(currentPage);
      await fetchData(currentPage, size);
    },
    onChange: async (page, size) => {
      setPageSize(size);
      setCurrentPageNum(page);
      await fetchData(page, size);
    },
  } as TablePaginationConfig;

  const fetchData = async (page = 1, size = 100) => {
    try {
      setLoading(true);
      const { data } = await apiClient.post(`/data-ingest/jobs/filteredOutput`, {
        jobGuid,
        fileName: 'consolidatedLog',
        filters: [
          {
            column: 'Validation_Info',
            operator: 'inc',
            value: selectedFilters.length ? selectedFilters : filters,
          }
        ],
        page,
        size
      });

      if (data.total) {
        setTotalRecords(data.total);
      };

      setData(data.results);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  const handleChange = (tag: string, checked: boolean) => {
    const updatedSelectedFilters = checked
      ? [...selectedFilters, tag]
      : selectedFilters.filter((t) => t !== tag);
    setSelectedFilters(updatedSelectedFilters);
  };

  React.useEffect(() => {
    setCurrentPageNum(1);
    fetchData();
  }, [selectedFilters]);

  React.useEffect(() => {
    fetchData();
  }, []);

  const errorColumns = [
    {
      title: 'Last name',
      dataIndex: 'lastName',
      key: 'Last name',
      width: '10%',
    },
    {
      title: 'First name',
      dataIndex: 'firstName',
      key: 'First name',
      width: '10%',
    },
    {
      title: 'Student ID',
      dataIndex: 'hsStudentId',
      key: 'Student ID',
      width: '10%',
    },
    {
      title: 'School ID',
      dataIndex: 'Naviance_School_ID',
      key: 'School ID',
      width: '10%',
    },
    {
      title: 'Class year',
      dataIndex: 'classYear',
      key: 'Class year',
      width: '6%',
    },
    ...(isCleverIntegration ?
      [{
        title: 'Clever Id',
        dataIndex: 'cleverId',
        key: 'Clever Id',
        width: '12%',
      }] : []
    ),
    ...(dataType === "alumni" ?
      [
        {
          title: 'Gradutation date',
          dataIndex: 'gradDate',
          key: 'Gradutation Date',
          width: '10%',
        },
        {
          title: 'Diploma type',
          dataIndex: 'diplomaType',
          key: 'Diploma type',
          width: '10%',
        }
      ] : []
    ),
    {
      title: 'Validation Info',
      dataIndex: 'Validation_Info',
      key: 'Validation Info',
      width: '15%',
    }
  ];

 const warningColumns: ColumnsType<StudentWarningTab> = [
   {
     title: 'Last name',
     dataIndex: 'lastName',
     key: 'Last name',
     width: 100,
     fixed: 'left',
   },
   {
     title: 'First name',
     dataIndex: 'firstName',
     key: 'First name',
     fixed: 'left',
   },
   {
     title: 'Student ID',
     dataIndex: 'hsStudentId',
     key: 'Student ID',
     fixed: 'left',
   },
   {
     title: 'GPA',
     dataIndex: 'gpa',
     key: 'GPA',
   },
   {
     title: 'ZIP',
     dataIndex: 'zip',
     key: 'ZIP',
   },
   {
     title: 'City',
     dataIndex: 'city',
     key: 'City',
   },
   {
     title: 'Rank',
     dataIndex: 'rank',
     key: 'Rank',
   },
   {
     title: 'Email',
     dataIndex: 'email',
     key: 'Email',
   },
   {
     title: 'State',
     dataIndex: 'state',
     key: 'State',
   },
   {
     title: 'Gender',
     dataIndex: 'gender',
     key: 'Gender',
   },
   {
     title: 'Birth Date',
     dataIndex: 'birthDate',
     key: 'Birth Date',
   },
   {
     title: 'Middle Name',
     dataIndex: 'middleName',
     key: 'Middle Name',
   },
   {
     title: 'Weighted GPA',
     dataIndex: 'weightedGpa',
     key: 'Weighted GPA',
   },
   {
     title: 'Street Address',
     dataIndex: 'streetAddress',
     key: 'Street Address',
   },
   {
     title: 'State Student ID',
     dataIndex: 'stateStudentId',
     key: 'State Student ID',
   },
   {
     title: 'Grade',
     dataIndex: 'grade',
     key: 'Grade',
   },
   {
     title: 'Home Phone',
     dataIndex: 'homePhone',
     key: 'Home Phone',
   },
   {
     title: 'Ethnic Code',
     dataIndex: 'ethnicCode',
     key: 'Ethnic Code',
   },
   {
     title: 'Student Group',
     dataIndex: 'studentGroup',
     key: 'Student Group',
   },
   {
     title: 'Original Class Year',
     dataIndex: 'originalClassYear',
     key: 'Original Class Year',
   },
   {
      title: 'Validation Info',
      dataIndex: 'Validation_Info',
      key: 'Validation Info',
   } 
 ];

  return (
    <div className="errorsAndWarningTab">
      <Row>
        <Col span={24}>
          {filters.map((tag) => (
            <Tag.CheckableTag
              key={tag}
              checked={selectedFilters.includes(tag)}
              onChange={(checked) => handleChange(tag, checked)}
            >
              <>
                <ChipIcon checked={selectedFilters.includes(tag)} />
                {tag}
              </>
            </Tag.CheckableTag>
          ))}
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={24}>
          <Table
            columns={type === TabType.Error ? errorColumns : warningColumns}
            dataSource={data}
            loading={loading}
            pagination={pagination}
            scroll={{ x: 'max-content' }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default StudentsErrorsAndWarningsTab;