import * as React from 'react';
import Col from '../../components-v2/Col';
import Row from '../../components-v2/Row';
import ConfigButton from '../PSConfigure/ConfigButton';

const SftpConfigure = () : React.ReactElement => {
  return (
    <div>
      <p>
        Complete <b>SFTP setup</b> before any data upload using SFTP
      </p>
      <Row>
        <Col span={20}>
          <ConfigButton id="sis_integration_setup" to={'/sftp'}>
            Configure SFTP
          </ConfigButton>
          {/* <b>Status:</b> <SisConnection /> */}
          <p className="configText" data-test-id="ps_sftp_configure">
            Review and copy SFTP settings to your SFTP tool.
          </p>
        </Col>
      </Row>
      <Row>
        <Col span={20}>
          <ConfigButton id="sis_integration_setup" to={'/import-wizard/oneroster/upload-file'}>
            OneRoster import settings
          </ConfigButton>
          {/* <b>Status:</b> <SisConnection /> */}
          <p className="configText" data-test-id="ps_one_roster_import_settings">
            Import settings between Naviance and OneRoster.
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default SftpConfigure;
