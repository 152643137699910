import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { StepStatus, WizardStep } from '../../components-v2/Wizard/sideBar';
import { IntegrationContext } from './../../utils/context';

interface SisWizardStep extends WizardStep {
  content: React.FunctionComponent<any>;
}

export interface SisImportStep {
  title: string;
  content: React.FunctionComponent<any>;
  path: string;
}

export interface SisImportProps {
  steps: SisImportStep[];
}

const stepStatus = (currentStep, stepIndex): StepStatus => {
  return (currentStep === stepIndex ? 'process' : 'wait') as StepStatus;
};

const SisImport = (props: SisImportProps): React.ReactElement => {
  const history = useHistory();
  const location = useLocation();
  const [currentStepNum, setCurrentStepNum] = React.useState(0);
  const { dataImportPath, isOneRosterIntegration } = React.useContext(IntegrationContext);

  const steps = props.steps.map((step, index) => {
    const status = stepStatus(currentStepNum, index);
    return {
      title: step.title,
      status,
      content: step.content,
      path: step.path,
    };
  });

  React.useEffect(() => {
    const pathFromUrl = props.steps.findIndex((step) => step.path === location.pathname);
    if (pathFromUrl !== -1 && currentStepNum !== pathFromUrl) {
      setCurrentStepNum(pathFromUrl);
    } else {
      setCurrentStepNum(0);
      if (steps[currentStepNum].path) {
        history.push(steps[currentStepNum].path);
      }
    }
  }, []);

  const [currentStep, setCurrentStep] = React.useState<SisWizardStep>(steps[currentStepNum] as any);

  const continueFn = () => {
    const oldStep = currentStepNum;
    const newStep = currentStepNum + 1;

    steps[oldStep].status = stepStatus(newStep, oldStep);

    if (newStep < steps.length) {
      steps[newStep].status = stepStatus(newStep, newStep);
      setCurrentStepNum(newStep);
      if (steps[currentStepNum].path) {
        history.push(steps[newStep].path);
      }
    } else {
      history.push(dataImportPath);
    }
  };
  const previousFn = (goToStep?: number) => {
    const oldStep = currentStepNum;
    const newStep = goToStep || goToStep === 0 ? goToStep : currentStepNum - 1;

    steps[oldStep].status = stepStatus(newStep, oldStep);
    steps[newStep].status = stepStatus(newStep, newStep);

    setCurrentStepNum(newStep);
    if (steps[currentStepNum].path) {
      history.push(steps[newStep].path);
    }
  };
  const saveFn = () => {
    console.log('Saved!');
  };
  const cancelFn = () => {
    previousFn(0);
    if (isOneRosterIntegration) {
      history.push('/configure');
    } else {
      history.push('/configure/import-settings');
    }
  };

  React.useEffect(() => {
    setCurrentStep(steps[currentStepNum] as any);
  }, [currentStepNum]);

  return (
    <currentStep.content
      steps={steps}
      currentStep={currentStepNum}
      saveFn={saveFn}
      cancelFn={cancelFn}
      continueFn={continueFn}
      previousFn={previousFn}
      changeStepFn={previousFn}
    />
  );
};

export default SisImport;
