import * as React from 'react';
import { message, Spin } from 'antd';
import Col from '../../components-v2/Col';
import Row from '../../components-v2/Row';
import MainTemplate from '../Shell/MainTemplate';
import apiClient from '../../utils/apiClient';
import { showNotification, NotificationTypes } from '../../components/Notifications';
import { IntegrationContext } from '../../utils/context';

const SftpSettings = (): React.ReactElement => {
  const { districtAlias, userEmail, userType } = React.useContext(IntegrationContext);
  const [loading, setLoading] = React.useState(true);
  const [isGenerating, setIsGenerating] = React.useState(false);
  const [sftpSettings, setSftpSettings] = React.useState<Record<string, string>>({
    password: '',
    sftpServerId: '',
    username: '',
    sftpServerDomain: '',
  });
  const [colSpan, setColSpan] = React.useState(5);

  const fetchSftpSettings = React.useCallback(async () => {
    if (!districtAlias || !userEmail) return;
    try {
      if (userType !== 4) {
        const response = await apiClient.get(`/data-ingest/tenantuser/check-user/${districtAlias}`, {
          headers: { email: userEmail },
        });

        if (response.data?.sftpServerDomain.includes('amazonaws.com')) {
          setColSpan(8);
        }
        setLoading(false);
        setSftpSettings(response.data || {});
      }
    } catch (error) {
      setLoading(false);
      showNotification(NotificationTypes.error, 'Error fetching SFTP settings', 'Error fetching SFTP settings');
    }
  }, [districtAlias, userEmail, userType]);

  const generateNewPassword = React.useCallback(async () => {
    try {
      setIsGenerating(true);
      if (userType !== 4) {
        const response = await apiClient.get(
          `/data-ingest/tenantuser/rotate-user-password/${districtAlias}`,
          { headers: { email: userEmail } }
        );
        setIsGenerating(false);
        setSftpSettings((prevSettings) => ({
          ...prevSettings,
          password: response.data.password,
        }));
      }
    } catch (error) {
      setIsGenerating(false);
      showNotification(NotificationTypes.error, 'Error generating new password', 'Error generating new password');
    }
  }, [districtAlias, userEmail, userType]);

  React.useEffect(() => {
    fetchSftpSettings();
  }, [fetchSftpSettings]);

  /** Copy text to clipboard */
  const copyToClipboard = React.useCallback((text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      message.success('Copied to clipboard!');
    }).catch(() => {
      message.error('Failed to copy!');
    });
  }, []);

  return (
    <MainTemplate title="SFTP Settings" titleClassName="sftpSettingsTitle" titleTestId="sftpSettingsTitle">
      {loading ? (
        <div className="spinner" data-testid="loading-spinner">
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <p>
            Review <b>and copy</b> SFTP settings to your SFTP tool.
          </p>

          {/* Upload URL */}
          <Row>
            <Col span={colSpan} data-testid="upload-url-col">
              <label>Upload URL</label>
              <p className="configText" data-testid="ps_sftp_configure">
                <b>{sftpSettings.sftpServerDomain}</b>
              </p>
            </Col>
            <Col span={4}>
              <button
                className="button"
                data-testid="copy-button"
                onClick={() => copyToClipboard(sftpSettings.sftpServerDomain)}
              >
                Copy
              </button>
            </Col>
          </Row>

          {/* Port */}
          <Row>
            <Col span={colSpan}>
              <label>PORT</label>
              <p className="configText" data-testid="ps_sftp_port">
                <b>{22}</b>
              </p>
            </Col>
            <Col span={4}>
              <button
                className="button"
                data-testid="copy-button"
                onClick={() => copyToClipboard("22")}
              >
                Copy
              </button>
            </Col>
          </Row>

          {/* Username */}
          <Row>
            <Col span={colSpan}>
              <label>Username</label>
              <p className="configText" data-testid="ps_sftp_username">
                <b>{sftpSettings.username}</b>
              </p>
            </Col>
            <Col span={4}>
              <button
                className="button"
                data-testid="copy-button"
                onClick={() => copyToClipboard(sftpSettings.username)}
              >
                Copy
              </button>
            </Col>
          </Row>

          {/* Password */}
          <Row>
            <Col span={colSpan}>
              <label>Password</label>
              <p className="configText" data-testid="ps_sftp_password">
                <b>{sftpSettings.password ? sftpSettings.password : '************'}</b>
              </p>
            </Col>
            <Col span={4}>
                <button
                  className={`button${sftpSettings.password ? '' : '-diasbled'}`}
                  data-testid="copy-button"
                  onClick={() => copyToClipboard(sftpSettings.password)}
                  disabled={!sftpSettings.password}
                >
                  Copy
                </button> &nbsp;
                <button 
                  className={`button${(!isGenerating && !sftpSettings.password)  ? '' : '-diasbled'}`}
                  data-testid="generate-password-button" 
                  onClick={generateNewPassword}
                  disabled={(isGenerating) ? true : (sftpSettings.password) ? true : false}
                >
                  Generate new {isGenerating && <Spin size="small" />}
                </button>
              
            </Col>
          </Row>
        </div>
      )}
    </MainTemplate>
  );
};

export default SftpSettings;
