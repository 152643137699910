import { createContext } from 'react';

export const IntegrationContext = createContext({
  isCleverIntegration: false,
  isOneRosterIntegration: false,
  dataImportPath: '/powerschool-data-integration',
  pluginDetails: {},
  sisServerDetails: {},
  sisError: false,
  isLegacyCleverEnabled: false,
  districtAlias: '',
  districtId: '',
  userFullName: '',
  userEmail: '',
  userType: 0,
  schoolMap: {},
});
