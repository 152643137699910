import { CloseOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Row, Col, Select, Tag } from 'antd';
import * as React from 'react';

const successColor = '#A0F8DE';
const successText = '#232328';
const errorColor = '#DA1E38';
const errorText = '#FFF';

const ConfigureEmail = (): React.ReactElement => {
  const [splitEmails, setSplitEmails] = React.useState<string[]>([]);

  const validateEmail = (email: string): boolean => {
    // Regex to validate widely used email formats
    const emailRegexToTest =
      /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    // do not evaluate if email is empty
    if (email.length > 256 || !emailRegexToTest.test(email)) return false;
    // Check on the length of the domain part and the account part of an email which regex does not evaluate
    const [account, address] = email.split('@');
    if (account.length > 64) return false;
    const emailDomainParts = address.split('.');
    return !emailDomainParts.some((domainPart) => domainPart.length > 63);
  };
  
  const tagRender = (props) => {
    const { value, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const validEmail = validateEmail(value);
    return (
      <Tag
        key={value}
        onClose={onClose}
        closable={true}
        onMouseDown={onPreventMouseDown}
        style={{ color: validEmail ? successText : errorText, fill: validEmail ? successText : errorText }}
        closeIcon={
          <CloseOutlined
            style={{ color: validEmail ? successText : errorText, fill: validEmail ? successText : errorText }}
          />
        }
        color={validEmail ? successColor : errorColor}
        icon={validEmail ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
      >
        {value}
      </Tag>
    );
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Select
                mode="tags"
                size="large"
                tagRender={tagRender}
                defaultValue={splitEmails}
                style={{ width: '100%' }}
                options={[]}
                tokenSeparators={[',']}
                dropdownStyle={{ display: 'none' }}
                // placeholder="Add email addresses separated by a comma"
                onChange={(change) => {
                  setSplitEmails(change);
                }}
                className="emailRecipientSelector"
                data-testid="email_type"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ConfigureEmail;