import type { SisCredentialResponse, SisSchool } from '../../../../../../libs/common-interfaces';
import { Space, Spin } from 'antd';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import Col from '../../components-v2/Col';
import Row from '../../components-v2/Row';
import Select from '../../components-v2/Select';
import SchoolMatch from '../../components-v2/SchoolMatch';
import SisConnection from '../../components-v2/SisConnection';
import Drawer from '../../components/Drawer';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import apiClient from '../../utils/apiClient';
import { PSError, nonCleverIntegrations } from '../../utils/constants';
import MainTemplate from '../Shell/MainTemplate';
import SisCredentials from './SisCredentials';

import Title from 'antd/lib/typography/Title';
import { featureFlags } from '../../utils/featureFlags';
import { IntegrationContext } from './../../utils/context';
import { WizardProps } from '../../components-v2/Wizard';
import SchoolMapping from './SchoolMap';

const PSIntegrationSetup = (): React.ReactElement => {
  const { Option } = Select;

  const [drawer, setDrawer] = React.useState<null | 'cred' | 'loading'>(null);
  const [drawerLoading, setDrawerLoading] = React.useState<boolean>(false);
  const [credentials, setCredentials] = React.useState<SisCredentialResponse>({
    host: '',
    clientId: '',
    discoveryUrl: '',
    sisIntegration: '',
    dataHost: '',
  });
  const [sisSchools, setSisSchools] = React.useState<SisSchool[]>([]);
  const [unusedSisSchools, setUnusedSisSchools] = React.useState<SisSchool[]>([]);
  const [schoolMapping, setSchoolMapping] = React.useState<any>([]);
  const [disableSave, setDisableSave] = React.useState(true);
  const [schoolMatchLoading, setSchoolMatchLoading] = React.useState(false);
  const [reloadSisConnection, setReloadSisConnection] = React.useState(false);
  const [sisIntegration, setSisIntegration] = React.useState<string>('psSis');
  const [sisIntegrationMeta, setSisIntegrationMeta] = React.useState<string>(null);
  const [host, sethost] = React.useState<string>('');
  const [showTestConnLoader, setShowTestConnLoader] = React.useState(false);
  const { isCleverIntegration, districtId } = React.useContext(IntegrationContext);

  const history = useHistory();

  const searchParams = new URLSearchParams(window.location.search);
  const setup = searchParams.get('setup');

  const isSISOptionDisabled = (sisType: string) => {
    let disabled = '';
    if (setup && sisType !== setup) {
      disabled = 'disabled';
    }
    return disabled;
  };

  React.useEffect(() => {
    if (['eSP', 'psSis', 'clever'].includes(setup)) {
      setDrawer('cred');
      setSisIntegration(setup);
    }
  }, []);

  const fetchSisSchools = async () => {
    try {
      const { data } = await apiClient.get('/data-ingest/sis/sis-schools');
      const psSchools = data.filter((school) => school.school_number !== '999999');
      setSisSchools(psSchools);
      setUnusedSisSchools(psSchools);
    } catch (error) {
      if (error.message !== PSError) {
        showNotification(NotificationTypes.error, 'Error Getting SIS Schools', 'Failure in getting data from server.');
      }
    }
  };

  const saveSchoolMapping = async () => {
    try {
      const data = Object.keys(schoolMapping).reduce((acc, nid) => {
        const { sisId } = schoolMapping[nid];
        sisId.forEach((id) => {
          acc.push({ sisId: id, nid });
        });
        return acc;
      }, []);

      if (sisIntegration === 'psSis' || sisIntegration === 'clever') {
        const isDistrictAdded = data.find((schoolEntry: any) => schoolEntry.sisId === '0');
        if (!isDistrictAdded) {
          data.push({ sisId: '0', nid: districtId });
        }
      }

      const totalMappings = data.length;
      const batchSize = 125;
      const batches = Array.from({ length: Math.ceil(totalMappings / batchSize) }, (v, i) =>
        data.slice(i * batchSize, i * batchSize + batchSize),
      );

      for (let i = 0; i < batches.length; i++) {
        await apiClient.patch('/data-ingest/sis/parameter-group/schoolMappingBatches', {
          schoolMapping: batches[i],
          batchNumber: i,
        });
      }

      showNotification(NotificationTypes.success, 'Success', 'Mapping updated successfully');
      goToConfigPage();
    } catch (error) {
      showNotification(NotificationTypes.error, 'Error Saving School Mappings', 'Failure in saving data on server.');
    }
  };

  const goToConfigPage = async () => {
    history.push('/configure');
  };

  async function setupCleverConfig(cleverId: string) {
    try {
      setShowTestConnLoader(true);
      const patchBody = {
        cleverId: cleverId,
        sisIntegration: 'clever',
      };
      await apiClient.patch('data-ingest/sis/tenant-meta', patchBody);
      await fetchTenantConfig();
      setShowTestConnLoader(false);
      setReloadSisConnection(true);
      if (featureFlags['feature.dataIngest.auto.disableLegacyClever']) {
        disableLegacyClever();
      }
    } catch (error) {
      setShowTestConnLoader(false);
    }
  }

  async function disableLegacyClever() {
    await apiClient.get('data-ingest/sis/disable-legacy-clever');
  }

  const cscBtns = (dataTestIdPrefix) => (
    <Space>
      <button className="button" onClick={goToConfigPage} data-test-id={`${dataTestIdPrefix}_cancel_btn`}>
        Cancel
      </button>
      <button
        className="buttonSolid"
        disabled={disableSave}
        onClick={saveSchoolMapping}
        data-test-id={`${dataTestIdPrefix}_save_btn`}
      >
        Save
      </button>
    </Space>
  );

  const renderDrawer = () => {
    if (drawer) {
      return (
        <Drawer
          visible={true}
          onClose={closeDrawer}
          content={
            drawer === 'cred' ? (
              <div className="mainDrawer">
                <Row>
                  <Col span={24}>
                    <Title className="drawerTitle" level={1} data-test-id="drawer_title">
                      {isCleverIntegration ? 'Clever' : 'SIS'} Integration Settings
                    </Title>
                    {!isCleverIntegration && sisIntegration === 'psSis' && (
                      <p>
                        Download and install the PowerSchool Plugin to integrate Naviance with PowerSchool SIS. Then
                        enter the URL, Client ID and Client Secret below.
                      </p>
                    )}
                  </Col>
                  {!isCleverIntegration && (
                    <Col span={24} className="drawerSelectContainer">
                      <span className="drawerLableText disabled">Select SIS</span>
                      <Select
                        value={sisIntegration}
                        onChange={(value) => {
                          setDrawerLoading(true);
                          setCredentials({
                            host: '',
                            clientId: '',
                            discoveryUrl: '',
                            sisIntegration: value,
                          });
                          setSisIntegration(value);
                          setDrawerLoading(false);
                        }}
                        data-test-id="sis_selection"
                      >
                        <Option value="psSis" disabled={isSISOptionDisabled('psSis')}>
                          PowerSchool SIS
                        </Option>
                        <Option value="eSP" disabled={isSISOptionDisabled('eSP')}>
                          eSchoolPlus SIS
                        </Option>
                      </Select>
                    </Col>
                  )}
                </Row>
                {drawerLoading ? (
                  <Col span={24} style={{ alignContent: 'center', left: '50%' }}>
                    <div className="spinner">
                      <Spin size="large" />
                    </div>
                  </Col>
                ) : (
                  <SisCredentials
                    credentials={credentials}
                    save={setCredentials}
                    sisIntegration={sisIntegration}
                    sisIntegrationMeta={sisIntegrationMeta}
                    hostMeta={host}
                    disableLegacyClever={disableLegacyClever}
                    close={() => setDrawer(null)}
                  />
                )}
              </div>
            ) : null
          }
        />
      );
    }
  };
  const closeDrawer = () => {
    setDrawer(null);
    setReloadSisConnection(true);
  };

  const fetchTenantConfig = async () => {
    try {
      setDrawerLoading(true);
      const { data } = await apiClient.get(`/data-ingest/sis/tenant-meta`);
      let creds = credentials;
      // If the tenant meta sisIntegration and
      //the integration configuration page are not in sync then set blank host data
      if (!isCleverIntegration && data.sisIntegration === 'clever') {
        creds = { host: '', clientId: '', sisIntegration: 'psSis' };
        setSchoolMatchLoading(false);
        setSisIntegration('psSis');
      } else {
        if (setup && data.host) {
          setSisIntegration(data.sisIntegration);
        }
        creds = data;
      }
      setSisIntegrationMeta(data.sisIntegration);
      sethost(data.host);
      setShowTestConnLoader(false);
      if (!data.host && !data.clientId) {
        setSchoolMatchLoading(false);
      }

      if (!nonCleverIntegrations.includes(data.sisIntegration) && isCleverIntegration) {
        const { data: districtData } = await apiClient.get('/district/clever-details');
        data['cleverId'] = districtData.cleverId;
        if (data.sisIntegration !== 'clever' && data.cleverId) {
          setupCleverConfig(data.cleverId);
        }
      }

      setCredentials(creds);
      setDrawerLoading(false);
      setSchoolMatchLoading(false);
    } catch (error) {
      showNotification(NotificationTypes.error, 'Error Getting Config', 'Failure in getting data from server.');
    }
  };

  React.useEffect(() => {
    if (isCleverIntegration) {
      setShowTestConnLoader(true);
    }
    void fetchTenantConfig();
  }, [drawer]);

  return (
    <>
      <MainTemplate
        title={`${isCleverIntegration ? 'Clever Data' : 'SIS'} Integration Setup`}
        titleClassName="integrationSetupTitle"
        subheaderRSContent={cscBtns('upper')}
      >
        <div>
          <Row>
            <Col span={20}>
              <div>
                <h1>
                  {isCleverIntegration ? 'Clever Data' : 'SIS'} Integration Status:{' '}
                  <SisConnection
                    reload={reloadSisConnection}
                    afterReload={() => setReloadSisConnection(false)}
                    loadingPlaceHolder={showTestConnLoader}
                  ></SisConnection>
                </h1>

                <>
                  <button
                    className="button"
                    data-test-id="sis_credentials_link"
                    onClick={(e) => {
                      e.preventDefault();
                      setDrawer('cred');
                    }}
                  >
                    Edit {isCleverIntegration ? 'Clever' : 'SIS'} Integration Settings
                  </button>
                </>
              </div>
            </Col>
          </Row>
          {renderDrawer()}
        </div>
      </MainTemplate>
      <MainTemplate title="">
        <SchoolMapping
          sisSchools={sisSchools}
          unusedSisSchools={unusedSisSchools}
          setUnusedSisSchools={setUnusedSisSchools}
          fetchSisSchools={fetchSisSchools}
          credentials={credentials}
          schoolMapping={schoolMapping}
          setSchoolMapping={setSchoolMapping}
          schoolMatchLoading={schoolMatchLoading}
          setSchoolMatchLoading={setSchoolMatchLoading}
          setDisableSave={setDisableSave}
        />
      </MainTemplate>
    </>
  );
};

export default PSIntegrationSetup;
