import { ComponentClass, FunctionComponent } from 'react';
import CoursePlannerImport from '../containers/CoursePlanner';
import DataImport from '../containers/scholarships/DataImport';
import Configurations from '../containers/scholarships/DataImportConfigurations';
import DefineCodes from '../containers/scholarships/DefineCodes';
import Import from '../containers/scholarships/Import';
import ImportNotifications from '../containers/ImportNotifications';
import JobLogs from '../containers/scholarships/Logs';
import MatchFields from '../containers/scholarships/MatchFields';
import NewDataImport from '../containers/scholarships/NewDataImport';
import PSConfigure from '../containers/PSConfigure';
import PSDataImport from '../containers/PSDataImport';
import PSDataImportConnectSettings from '../containers/PSDataImportConnectSettings';
import PSDataImportJobSummary from '../containers/PSDataImportJobSummary';
import PSIntegrationSetup from '../containers/PSIntegrationSetup';
import SavedImportSettings from '../containers/scholarships/SavedImportSettings';
import SisAlumniImport, { AlumniImportSteps } from '../containers/SisImport/SisAlumniImport';
import SisCurrentCourses, { StudentCourseDataSteps } from '../containers/SisImport/SisCurrentCourses';
import SisCourseHistory, { CourseHistorySteps } from '../containers/SisImport/SisCourseHistory';
import SisStudentImport, { StudentImportSteps } from '../containers/SisImport/SisStudentImport';
import UpdatesLog from '../containers/UpdatesLog';
import { ImportType } from '../containers/UpdatesLog';
import { ImportTypeParam } from './constants';
import { getImportName } from './utils';
import SisCourseCatalog, { CourseCatalogSteps } from '../containers/SisImport/SisCourseCatalog';
import SisCourseMapping, { CourseMappingSteps } from '../containers/SisImport/SisCourseMapping';
import SisStaffRecords, { StaffRecordsSteps } from '../containers/SisImport/SisStaffRecords';
import SisParentRecords, { ParentRecordsSteps } from '../containers/SisImport/SisParentRecords';
import SyncCourseRequest from '../containers/SyncCourseRequest';
import SftpConfigure from '../containers/ConfigureSftp';
import SftpSettings from '../containers/ConfigureSftpSettings';
import OneRosterImportComponent, { OneRosterImportSteps } from '../containers/SisImport/OneRosterStaffRecords';

export interface Routes {
  path: string;
  name: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  page: ComponentClass | FunctionComponent<object>;
  logo?: React.FunctionComponent;
  props?: Record<string, unknown>;
  breadcrumbs?: string[];
}

const routes = (integrationContext) => {
  const { isCleverIntegration, isOneRosterIntegration } = integrationContext;
  const integrationType = isCleverIntegration
    ? 'Clever data'
    : isOneRosterIntegration
    ? 'OneRoster'
    : 'PowerSchool Data';

  const routes: { [key: string]: Routes } = {
    [`/${ImportTypeParam.CourseCatalog}`]: {
      path: '/',
      page: CoursePlannerImport,
      name: getImportName(ImportTypeParam.CourseCatalog),
      props: { importType: ImportTypeParam.CourseCatalog },
    },
    [`/${ImportTypeParam.CourseMapping}`]: {
      path: '/',
      page: CoursePlannerImport,
      name: getImportName(ImportTypeParam.CourseMapping),
      props: { importType: ImportTypeParam.CourseMapping },
    },
    [`/${ImportTypeParam.StudentCourseData}`]: {
      path: '/',
      page: CoursePlannerImport,
      name: getImportName(ImportTypeParam.StudentCourseData),
      props: { importType: ImportTypeParam.StudentCourseData },
    },
    '/scholarships-import': {
      path: '/',
      page: Import,
      name: getImportName(ImportTypeParam.Scholarship),
      props: { importType: ImportTypeParam.Scholarship },
    },
    '/scholarships': {
      path: '/',
      page: DataImport,
      name: 'Data Import',
    },
    '/scholarships/logs': {
      path: '/',
      page: JobLogs,
      name: 'Job Logs',
    },
    '/scholarships/new-data-import': {
      path: '/',
      page: NewDataImport,
      name: 'New Data Import',
    },
    '/scholarships/new-data-import/match-fields': {
      path: '/',
      page: MatchFields,
      name: 'Match Fields',
    },
    '/scholarships/new-data-import/define-codes': {
      path: '/',
      page: DefineCodes,
      name: 'Add students define codes',
    },
    '/scholarships/saved-import-settings': {
      path: '/',
      page: SavedImportSettings,
      name: 'Saved Import Settings',
    },
    '/scholarships/data-import-configuration': {
      path: '/',
      page: Configurations,
      name: 'Data import Configuration',
    },
    '/history': {
      path: '/',
      page: PSDataImport,
      name: `${integrationType} integration`,
    },
    '/view-log/:jobGuid': {
      path: '/',
      page: PSDataImportJobSummary,
      name: 'View Log',
      breadcrumbs: ['/history'],
    },
    '/configure': {
      path: '/',
      page: PSConfigure,
      name: 'Configure',
      breadcrumbs: ['/history'],
    },
    '/configure/integration': {
      path: '/',
      page: PSIntegrationSetup,
      name: (isCleverIntegration ? 'Clever' : 'SIS') + ' Integration Setup',
      breadcrumbs: ['/history', '/configure'],
    },
    '/configure/import-settings': {
      path: '/',
      page: PSDataImportConnectSettings,
      name: `${isCleverIntegration ? 'Clever data integration' : 'SIS Import'} settings`,
      breadcrumbs: ['/history', '/configure'],
    },
    '/configure/email': {
      path: '/',
      page: ImportNotifications,
      name: 'Manage Email Notifications',
      breadcrumbs: ['/history', '/configure'],
    },
    '/sync-course-request': {
      path: '/',
      page: SyncCourseRequest,
      name: 'Sync Course Requests',
      breadcrumbs: ['/history', '/configure'],
    },
    '/import-wizard/students': {
      path: '/',
      page: SisStudentImport,
      name: 'Student Records',
    },
    '/import-wizard/course-catalog': {
      path: '/',
      page: SisCourseCatalog,
      name: 'Course Catalog',
    },
    '/import-wizard/course-mapping': {
      path: '/',
      page: SisCourseMapping,
      name: 'Course Mapping',
    },
    '/import-wizard/course-current': {
      path: '/',
      page: SisCurrentCourses,
      name: 'Student Course Data (Current Courses)',
    },
    '/import-wizard/course-history': {
      path: '/',
      page: SisCourseHistory,
      name: 'Student Course Data (Course History)',
    },
    '/import-wizard/alumni': {
      path: '/',
      page: SisAlumniImport,
      name: 'Student Records (Alumni)',
    },
    '/studentupdates': {
      path: '/',
      page: UpdatesLog,
      name: 'Student Updates Log',
      breadcrumbs: ['/history'],
      props: { type: ImportType.STUDENT },
    },
    '/staffupdates': {
      path: '/',
      page: UpdatesLog,
      name: 'Staff Updates Log',
      breadcrumbs: ['/history'],
      props: { type: ImportType.STAFF },
    },
    '/import-wizard/staff-records': {
      path: '/',
      page: SisStaffRecords,
      name: 'Staff Records',
    },
    '/import-wizard/parent-records': {
      path: '/',
      page: SisParentRecords,
      name: 'Parent Records',
    },
    '/configure/sftp': {
      path: '/',
      page: SftpConfigure,
      name: 'Configure',
      breadcrumbs: isOneRosterIntegration ? ['/history'] : [],
    },
    '/configure/sftp/settings': {
      path: '/',
      page: SftpSettings,
      name: 'STFP Settings',
      breadcrumbs: isOneRosterIntegration ? ['/history', '/configure/sftp'] : ['/configure/sftp'],
    },
    '/import-wizard/oneroster': {
      path: '/',
      page: OneRosterImportComponent,
      name: 'OneRoster Import',
      breadcrumbs: isOneRosterIntegration ? ['/history', '/import-wizard/file'] : ['/import-wizard/file'],
    },
    '/sftp': {
      path: '/',
      page: SftpSettings,
      name: 'SFTP Settings',
      breadcrumbs: (isOneRosterIntegration ? ['/history', '/configure'] : []),
    }
  };
  StudentImportSteps.forEach((route) => {
    routes[route.path] = {
      path: '/',
      name: 'Student Records',
      page: SisStudentImport,
    };
  });
  AlumniImportSteps.forEach((route) => {
    routes[route.path] = {
      path: '/',
      name: 'Student Records (Alumni)',
      page: SisAlumniImport,
    };
  });
  StaffRecordsSteps.forEach((route) => {
    routes[route.path] = {
      path: '/',
      name: 'Staff records',
      page: SisStaffRecords,
    };
  });

  OneRosterImportSteps.forEach((route) => {
    routes[route.path] = {
      path: '/',
      name: 'OneRoster Import',
      page: OneRosterImportComponent,
    };
  });
  ParentRecordsSteps.forEach((route) => {
    routes[route.path] = {
      path: '/',
      name: 'Parent Records',
      page: SisParentRecords,
    };
  });
  CourseCatalogSteps.forEach((route) => {
    routes[route.path] = {
      path: '/',
      name: 'Course Catalog',
      page: SisCourseCatalog,
    };
  });
  CourseHistorySteps.forEach((route) => {
    routes[route.path] = {
      path: '/',
      name: 'Student Course Data (Course History)',
      page: SisCourseHistory,
    };
  });
  CourseMappingSteps.forEach((route) => {
    routes[route.path] = {
      path: '/',
      name: 'Course Mapping',
      page: SisCourseMapping,
    };
  });
  StudentCourseDataSteps.forEach((route) => {
    routes[route.path] = {
      path: '/',
      name: 'Student Course Data (Current Courses)',
      page: SisCurrentCourses,
    };
  });
  return routes;
};

export default routes;
export const defaultRoute = routes['/data-import'];
