import React from 'react';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import SchoolMatch from '../../components-v2/SchoolMatch';
import apiClient from '../../utils/apiClient';
import { IntegrationContext } from '../../utils/context';
import { SisSchool } from '../../../../../../libs/common-interfaces';

type navSchoolsType = {
  highestGrade: number;
  lowestGrade: number;
  name: string;
  nid: string;
  schoolLevel: string;
  districtAssignedId?: string;
};

interface SchoolMapping {
  setDisableSave?: (disable: boolean) => void;
  sisSchools: SisSchool[];
  unusedSisSchools: SisSchool[];
  setUnusedSisSchools: (unusedSisSchools: SisSchool[]) => void;
  fetchSisSchools: () => void;
  credentials?: any;
  setReloadSisConnection?: (reload: boolean) => void;
  schoolMapping?: any;
  setSchoolMapping?: (schoolMapping: any) => void;
  schoolMatchLoading?: boolean;
  setSchoolMatchLoading?: (loading: boolean) => void;
}

const fetchNavianceSchools = async (setNavSchools: React.Dispatch<React.SetStateAction<navSchoolsType[]>>) => {
  try {
    const res = await apiClient.get('/highschools/v2?byType=district&includeInactive=true');
    const data: navSchoolsType[] = res.data;
    setNavSchools(data.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0)));
  } catch (error) {
    showNotification(NotificationTypes.error, 'Error Getting Naviance Schools', 'Failure in getting data from server.');
    throw error;
  }
};

const fetchSchoolMapping = async (setSchoolMapping: (mapping: any) => void) => {
  try {
    const { data } = await apiClient.get('/data-ingest/sis/parameter-group/schoolMapping');
    if (data.schoolMapping) {
      const mapping = data.schoolMapping.reduce((acc, value) => {
        const { sisId, nid } = value;
        if (acc[nid]) {
          acc[nid] = { nid, sisId: [...acc[nid].sisId, sisId] };
        } else {
          acc[nid] = { nid, sisId: [sisId] };
        }
        return acc;
      }, []);
      setSchoolMapping(mapping);
    } else {
      setSchoolMapping([]);
    }
  } catch (error) {
    showNotification(NotificationTypes.error, 'Error Getting School Mappings', 'Failure in getting data from server.');
    throw error;
  }
};

const calculateAvailableSisSchools = (schoolMapping: any, sisSchools: SisSchool[]) => {
  const usedSisSchools = Object.keys(schoolMapping).reduce((acc, nid) => {
    const { sisId } = schoolMapping[nid];
    sisId.forEach((id) => {
      acc.push(id);
    });
    return acc;
  }, []);
  return sisSchools.filter((school) => !usedSisSchools.includes(school.school_number));
};

const SchoolMapping = (props: SchoolMapping): React.ReactElement => {
  const {
    schoolMapping,
    setSchoolMapping,
    schoolMatchLoading,
    setSchoolMatchLoading,
    setDisableSave,
    sisSchools,
    unusedSisSchools,
    setUnusedSisSchools,
    fetchSisSchools,
    credentials = {},
    setReloadSisConnection,
  } = props;

  const [navSchools, setNavSchools] = React.useState<navSchoolsType[]>([]);
  const { isOneRosterIntegration } = React.useContext(IntegrationContext);

  const handleMappingChange = (nid, changedMap) => {
    const updatedMapping = { ...schoolMapping };
    updatedMapping[nid] = changedMap;
    updatedMapping[nid].enabled = !!updatedMapping[nid].sisId;
    setSchoolMapping(updatedMapping);
    setDisableSave(false);
  };

  const initializeData = async () => {
    if ((credentials.host && credentials.clientId) || isOneRosterIntegration) {
      setReloadSisConnection && setReloadSisConnection(true);
      setSchoolMatchLoading(true);
      try {
        await Promise.all([fetchSisSchools(), fetchNavianceSchools(setNavSchools)]);
        await fetchSchoolMapping(setSchoolMapping);
      } finally {
        setSchoolMatchLoading(false);
      }
    } else if (!credentials.host && !credentials.clientId) {
      setSchoolMatchLoading(true);
      try {
        await fetchSchoolMapping(setSchoolMapping);
        setNavSchools([]);
      } finally {
        setSchoolMatchLoading(false);
      }
    }
  };

  React.useEffect(() => {
    void initializeData();
  }, [credentials, isOneRosterIntegration]);

  React.useEffect(() => {
    setUnusedSisSchools(calculateAvailableSisSchools(schoolMapping, sisSchools));
  }, [schoolMapping]);

  return (
    <div>
      <SchoolMatch
        navSchools={navSchools}
        schoolMapping={schoolMapping}
        schoolsToMatch={unusedSisSchools}
        onMappingChange={handleMappingChange}
        loading={schoolMatchLoading}
      />
    </div>
  );
};

export default React.memo(SchoolMapping);
