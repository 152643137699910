import * as React from 'react';
import { WizardProps } from '../../components-v2/Wizard';
import SisFieldMatching from './index';
import {
  defaultNavStaffOneRosterMapping,
  navianceStaffOneRosterFields,
} from '../../utils/fieldMapUtils';
import SisMatchDescriptionHeader from '../../components-v2/SisMatchDescriptionHeader';
import SisWizardHeader from '../../components-v2/SisWizardHeader';

const OneRosterStaffRecordsMatching = (props: WizardProps): React.ReactElement => {
  const sections = [
    {
      tableHeader: <SisWizardHeader text={'Staff records'} />,
      defaultFields: navianceStaffOneRosterFields,
      defaultBlankValue: '(Do not import)',
    },
  ];
  const customHeaders = {
    isDistrict: 'isdistrict',
    isTeacher: 'isteacher',
    schoolid: 'orgSourcedIds',
    entityId: 'orgSourcedIds',
    'Nav Rolename': 'Nav Rolename',
  };
  return (
    <SisFieldMatching
      defaultMapping={defaultNavStaffOneRosterMapping}
      specificMapping={{ ...customHeaders }}
      allFieldsRequired={false}
      translateConfigEndPoint={'data-ingest/sis/parameter-group/staff-recordstranslateConfig'}
      extensionsEndpoint={'data-ingest/sis/staff-extension-tables'}
      removeUnmappedHeaders={false}
      removeEmptyHeaders={false}
      sections={sections}
      header={<SisMatchDescriptionHeader />}
      {...props}
    />
  );
};

export default OneRosterStaffRecordsMatching;
