import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Header as AntdHeader } from 'antd/lib/layout/layout';
import Layout from '../../components-v2/Layout';
import Routes from '../../routes';
import Header from './Header';
import apiClient from '../../utils/apiClient';
import Footer from './Footer';
import routes from '../../utils/routeConstants';
import { IntegrationContext } from './../../utils/context';

//This component will contain breadcrumbs and headers
export default ({ isDistrict }): React.ReactElement => {
  const { Content } = Layout;
  const location = useLocation();
  const integrationContext = useContext(IntegrationContext);
  const currentRoute = routes(integrationContext)[location.pathname];
  const showWizard = location.pathname.startsWith('/import-wizard');

  return (
    <Layout className={showWizard ? 'wizard-layout' : ''}>
      {showWizard ? (
        <>
          <AntdHeader className="wizard-header">
            <h2>
              {integrationContext.isCleverIntegration
              ? 'Clever data integration'
              : integrationContext.isOneRosterIntegration
              ? 'OneRoster Import Settings'
              : 'SIS Import Settings'}
            </h2>
            <h4>{currentRoute.name}</h4>
          </AntdHeader>
          <currentRoute.page {...(currentRoute.props || {})} />
        </>
      ) : (
        <Content className="main-content">
          <Fragment>
            <Header pathname={location.pathname} isDistrict={isDistrict}></Header>
            <Routes /> <Footer></Footer>
          </Fragment>
        </Content>
      )}
    </Layout>
  );
};
