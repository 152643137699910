import * as React from 'react';
import SisImport from '.';
import OneRosterFileUpload from '../OneRoster';
import OneRosterStaffRecordsMatching from '../SisFieldMatching/OneRosterStaffRecordsMatching';
import OneRosterSchoolMap from '../OneRoster/SchoolMapping';

export const OneRosterImportSteps = [
  { title: 'Upload file', content: OneRosterFileUpload, path: '/import-wizard/oneroster/upload-file' },
  { title: 'Header mapping', content: OneRosterStaffRecordsMatching, path: '/import-wizard/oneroster/header-mapping' },
  { title: 'School mapping', content: OneRosterSchoolMap, path: '/import-wizard/oneroster/school-map' },
  // { title: 'Role mapping', content: RoleMapping, path: '/import-wizard/oneroster/role-map' },
];

const OneRosterImportComponent = (): React.ReactElement => {
  return <SisImport steps={OneRosterImportSteps} />;
};

export default OneRosterImportComponent;
